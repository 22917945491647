import VueRouter from 'vue-router'
import Vue from 'vue'
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: '/wzsy',
    },
    {
        path: '/wzsy',
        component: () => import("@/view/wzsy/wzsy"),
        meta: {title: '翰墨轩影像艺术中心'}
    },
    {
        path: '/hyxw',
        component: () => import("@/view/hyxw/hyxw"),
        meta: {title: '行业新闻'},
        // children: [
        //     {
        //         path: 'console',
        //         component: () => import("@/view/hyxw/components/console")
        //     },
        //     {
        //         path: 'qwe',
        //         component: () => import("@/view/hyxw/components/qwe")
        //     },
        // ]
    },
    {
        path: '/zhanlan',
        component: () => import("@/view/hyxw/zhanlan"),
        meta: {title: '行业新闻'},
    },
	{
	    path: '/sheyin',
	    component: () => import("@/view/hyxw/sheyin"),
	    meta: {title: '行业新闻'},
	},
    {
        path: '/query',
        component: () => import("@/view/query/query"),
        meta: {title: '查询中心'}
    },
    {
        path: '/articles',
        component: () => import("@/view/hyxw/articles"),
        meta: {title: '行业新闻'},
    },
    {
        path: '/feiyi',
        component: () => import("@/view/hyxw/feiyi"),
        meta: {title: '行业新闻'},
    },
    {
        path: '/shengshi',
        component: () => import("@/view/hyxw/shengshi"),
        meta: {title: '行业新闻'},
    },
    {
        path: '/yantao',
        component: () => import("@/view/hyxw/yantao"),
        meta: {title: '行业新闻'},
    },
    {
        path: '/juxing',
        component: () => import("@/view/hyxw/juxing"),
        meta: {title: '行业新闻'},
    },
    {
        path: '/secai',
        component: () => import("@/view/hyxw/secai"),
        meta: {title: '行业新闻'},
    },
    {
        path: '/dangshi',
        component: () => import("@/view/hyxw/dangshi"),
        meta: {title: '行业新闻'},
    },
    {
        path: '/shzs',
        component: () => import("@/view/shzs/shzs"),
        meta: {title: '摄影展赛'}
    },
    {
        path: '/lanting',
        component: () => import("@/view/shzs/lanting"),
        meta: {title: '摄影展赛'}
    },
    {
        path: '/shixian',
        component: () => import("@/view/shzs/shixian"),
        meta: {title: '摄影展赛'}
    },
    {
        path: '/shuxiang',
        component: () => import("@/view/shzs/shuxiang"),
        meta: {title: '摄影展赛'}
    },
    {
        path: '/moyun',
        component: () => import("@/view/shzs/moyun"),
        meta: {title: '摄影展赛'}
    },
    {
        path: '/shcp',
        component: () => import("@/view/shcp/shcp"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpshixian',
        component: () => import("@/view/shcp/cpshixian"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cplanting',
        component: () => import("@/view/shcp/cplanting"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpshuxiang',
        component: () => import("@/view/shcp/cpshuxiang"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpsxsm',
        component: () => import("@/view/shcp/cpsxsm"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpsxsz',
        component: () => import("@/view/shcp/cpsxsz"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpltsm',
        component: () => import("@/view/shcp/cpltsm"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpltjx',
        component: () => import("@/view/shcp/cpltjx"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpsxds',
        component: () => import("@/view/shcp/cpsxds"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpmoyun',
        component: () => import("@/view/shcp/cpmoyun"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpmyjb',
        component: () => import("@/view/shcp/cpmyjb"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpmysm',
        component: () => import("@/view/shcp/cpmysm"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpmylc',
        component: () => import("@/view/shcp/cpmylc"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/cpmymd',
        component: () => import("@/view/shcp/cpmymd"),
        meta: {title: '摄影测评'}
    },
    {
        path: '/xmjs',
        component: () => import("@/view/shcp/xmjs"),
        meta: {title: '项目介绍'}
    },
    {
        path: '/cpdg',
        component: () => import("@/view/shcp/cpdg"),
        meta: {title: '测评大纲'}
    },
    {
        path: '/hdtz',
        component: () => import("@/view/shcp/hdtz"),
        meta: {title: '活动通知'}
    },
    {
        path: '/zpxs',
        component: () => import("@/view/zpxs/zpxs"),
        meta: {title: '作品欣赏'}
    },
    {
        path: '/works',
        component: () => import("@/view/zpxs/works"),
        meta: {title: '作品欣赏'}
    },
    {
        path: '/zhanting',
        component: () => import("@/view/zpxs/zhanting"),
        meta: {title: '作品欣赏'}
    },
    {
        path: '/mjdh',
        component: () => import("@/view/mjdh/mjdh"),
        meta: {title: '名家导航'}
    },
    {
        path: '/xgjg',
        component: () => import("@/view/xgjg/xgjg"),
        meta: {title: '相关机构'}
    },
    {
        path: '/zgscw',
        component: () => import("@/view/xgjg/zgscw"),
        meta: {title: '相关机构'}
    },
    {
        path: '/wxjjh',
        component: () => import("@/view/xgjg/wxjjh"),
        meta: {title: '相关机构'}
    },
    {
        path: '/shjlyh',
        component: () => import("@/view/xgjg/shjlyh"),
        meta: {title: '相关机构'}
    },
    {
        path: '/sfjxh',
        component: () => import("@/view/xgjg/sfjxh"),
        meta: {title: '相关机构'}
    },
    {
        path: '/wyplw',
        component: () => import("@/view/xgjg/wyplw"),
        meta: {title: '相关机构'}
    },
    {
        path: '/ysjlhh',
        component: () => import("@/view/xgjg/ysjlhh"),
        meta: {title: '相关机构'}
    },
    {
        path: '/about',
        component: () => import("@/view/about/about"),
        meta: {title: '联系我们'}
    },
]
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router