<template>
  <div>
    <div class="top">
      <div class="headTop">
        <img src="../../../src/assets/img/logo.png" style="cursor:pointer;" @click="gohome">
        <div class="menu hide">
          <div class="menuList" @click="goRouter(index)" @mouseover="mouseover(index)" @mouseleave="mouseleave"
               :class="selected==index?'select':''"
               v-for="(item,index) in menuList" :key="index">
            {{ item.title }}
            <div v-show="index==3&&xuanfu==3" class="xuanfu">
              <div class="shcplist" v-for="(item,index) in shcplist" @click.stop="goshcp(index)">
                {{ item.title }}
              </div>
            </div>
            <div v-show="index==4&&xuanfu==4" class="xuanfu">
              <div class="shcplist" v-for="(item,index) in zpxslist" @click.stop="gozpxs(item.title)">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <img class="menuicon show" @click="menuShow=!menuShow" src="../../../src/assets/img/menu_add.png">
        <div class="menushow" v-show="menuShow">
          <div v-for="(item,index) in menuList" :key="index">
            <div class="menushowlist" @click="goRouter(index)" :class="selected==index?'select':''">
              {{ item.title }}
            </div>
            <div v-show="index==3&&xialaindex==3" class="xialashowlist" v-for="(item,indexs) in shcplist" @click="goshcp(indexs)">
              {{ item.title }}
            </div>
            <div v-show="index==4&&xialaindex==4" class="xialashowlist" v-for="(item,indexs) in zpxslist" @click="gozpxs(item.title)">
              {{ item.title }}
            </div>
          </div>

        </div>
      </div>
      <!--      <div class="crumb" v-if="selected!=0">-->
      <!--        <img src="../../../src/assets/img/home.jpg">-->
      <!--        网站首页 > {{ menuList[selected].title }}-->
      <!--      </div>-->
    </div>
    <div>
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <div class="bottombox">
      <div class="btmcontent">
        <div class="btmr">
          <img src="../../../src/assets/img/ewm_f.png">
          <div>官方客服微信</div>
        </div>
        <div class="btmc">
          <img src="../../../src/assets/img/tel_ft.png">
        </div>
        <div class="btml">
          <img src="../../../src/assets/img/logobottom.png">
          <div>
            Copyright © 翰墨轩影像艺术中心 All Rights Reserved 闽ICP备2024082318号
          </div>
        </div>
      </div>
    </div>
    <div class="side" @click="toTop">
      <img src="../../../src/assets/img/side1.png">
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      xialaindex:0,
      menuList: [{title: '网站首页', path: '/wzsy'},
        {title: '行业新闻', path: '/hyxw'},
        {title: '摄影展赛', path: '/shzs'},
        {title: '摄影测评', path: '/shcp'},
        {title: '作品欣赏', path: '/zpxs'},
        {title: '名家导航', path: '/mjdh'},
        {title: '相关机构', path: '/xgjg'},
        {title: '证书查询', path: '/certificate'},
        {title: '联系我们', path: '/about'},],
      selected: 0,
      menuShow: false,
      xuanfu: '',
      shcplist: [
        {title: '项目介绍', path: '/xmjs'},
        {title: '测评大纲', path: '/cpdg'},
        {title: '活动通知', path: '/hdtz'},
      ],
      zpxslist: [
        {title: '人像展厅', path: '/shufa'},
        {title: '风光展厅', path: '/guohua'},
        {title: '艺术展厅', path: '/shici'},
        {title: '非遗展厅', path: '/youhua'},
        {title: '建筑展厅', path: '/zhuanke'},
      ]
    }
  },
  watch: {
    $route(to, from) {
      console.log(to.path)
      if (to.path == '/wzsy') {
        this.selected = 0
      } else if (to.path == '/hyxw') {
        this.selected = 1
      } else if (to.path == '/shzs') {
        this.selected = 2
      } else if (to.path == '/shcp' || to.path == '/xmjs' || to.path == '/cpdg' || to.path == '/hdtz') {
        this.selected = 3
      } else if (to.path == '/zpxs' || to.path == '/zhanting') {
        this.selected = 4
      } else if (to.path == '/mjdh') {
        this.selected = 5
      } else if (to.path == '/xgjg') {
        this.selected = 6
      } else if (to.path == '/zscx') {
        this.selected = 7
      } else if (to.path == '/about') {
        this.selected = 7
      }
    }
  },
  created() {


  },
  mounted() {
    setTimeout(() => {
      console.log(this.$route.path)
      this.menuList.forEach((item, index) => {
        if (this.$route.path == item.path) {
          this.selected = index
          console.log(this.selected)

        }
      })
    }, 30)
  },
  methods: {
    mouseover(e) {
      if (e == 3 || e == 4) {
        this.xuanfu = e
      }
    },
    gohome() {
      this.$router.push({
        path: '/wzsy'
      })
      this.selected = 0
    },
    mouseleave() {
      this.xuanfu = ''
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop = document.documentElement.scrollTop - 10;
        if (document.documentElement.scrollTop <= 0) {
          clearInterval(time)
        }
      }, 5)
      // document.documentElement.scrollTop = 0;
    },
    goshcp(e) {
      this.$router.push({
        path: this.shcplist[e].path
      })
      this.menuShow=false
    },
    gozpxs(title) {
      this.$router.push({
        path: '/zhanting?title=' + title
      })
      this.menuShow=false
    },
    goRouter(e) {
      if (e == 7) {
        window.open('http://handaguocui.com/certificate/#/');
        return
      }
      if(this.menuShow==true){
        if(e==3||e==4){
          this.xialaindex=e
          return;
        }
      }
      this.xialaindex=0
      this.menuShow = false
      this.selected = e
      this.$router.push({
        path: this.menuList[e].path
      })
      // if (e == 1) {
      //   this.$router.push({
      //     path: '/hyxw'
      //   })
      // } else if (e == 2) {
      //   this.$router.push({
      //     path: '/two'
      //   })
      // } else {
      //   this.$router.push({
      //     path: '/three'
      //   })
      // }

    },
  }
}
</script>

<style scoped lang="scss">
.top {
  width: 100%;
  background: #ffffff;

  .headTop {
    display: flex;
    padding: 0 30px;
    position: relative;
    align-items: center;
    background: #ffffff;
    height: 85px;
    border-bottom: 1px solid #eee;
    margin: 0 auto;
    max-width: 1250px;
    justify-content: space-between;

    .menushow {
      position: absolute;
      top: 85px;
      width: 100%;
      z-index: 3;
      font-size: 19px;
      color: white;
      left: 0px;

      .menushowlist {
        text-align: center;
        line-height: 40px;
        background: rgba(0, 0, 0, 0.9);
        border-bottom: #5a5a5a solid 1px;
      }
      .xialashowlist {
        text-align: center;
        line-height: 38px;
        border-bottom: #5a5a5a solid 1px;
        color: white;
        font-size: 17px;
        background: rgba(0, 0, 0, 0.9);
        background: #3f3d3d;
        opacity: 0.9;
      }
      .menushowlist:hover {
        background: #aa343c;
      }

      .select {
        background: #aa343c;
        color: white;
      }
    }

    img {
      width: 180px;
    }

    .menuicon {
      width: 35px;
      display: none;

    }

    .menu {
      display: flex;
      justify-content: space-between;

      .menuList {
        width: 82px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #575757;
        margin-right: 1px;
        position: relative;

        .xuanfu {
          position: absolute;
          top: 85px;
          background: #fff;
          width: 100%;
          text-align: center;

          .shcplist {
            height: 30px;
            line-height: 30px;
            color: #4f4949;
            font-size: 12px;
            border-bottom: #e8e8e8 solid 1px;
          }

          .shcplist:last-child {
            border: 0;
          }
        }
      }

      .menuList:hover {
        background: #aa343c;
        color: white;
        cursor: pointer;
      }

      .select {
        background: #aa343c;
        color: white;
      }
    }

  }

  .crumb {
    width: 1200px;
    margin: 0 auto;
    margin-top: 18px;
    color: #4d4d4d;
    padding: 0 30px;
    padding-bottom: 16px;

    margin-bottom: 4px;

    img {
      margin-right: 5px;
    }
  }
}

.side {
  width: 45px;
  position: fixed;
  right: 0;
  bottom: 80px;
  background: #666666;
  border-radius: 5px;

  img {
    width: 100%;
  }
}

.bottombox {
  background: #303030;
  overflow: hidden;
  padding: 28px 30px 15px;
  margin-top: 20px;

  .btmcontent {
    margin: 0 auto;
    display: flex;
    max-width: 1200px;
    flex-direction: row-reverse;

    .btmr {
      width: 114px;
      text-align: center;
      font-size: 14px;
      color: #bcbcbc;

      img {
        width: 114px;
        margin-bottom: 5px;
      }
    }

    .btmc {
      width: 40%;
      padding: 30px 0;
    }

    .btml {
      width: 50%;
      font-size: 14px;
      color: #a0a0a0;
      padding: 30px 0;

      div {
        margin-top: 30px;

      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .btmcontent {
    flex-wrap: wrap;
  }
  .btml {
    width: 100% !important;
    text-align: center;

  }
  .btmc {
    text-align: center;
    width: 100% !important;
  }
  .btmr {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
}
</style>